import { Button, ButtonProps } from '@mui/material';
import withTheme from '../withTheme';
import styles from './index.module.scss';

interface QSRButtonProps extends ButtonProps {}

const QSRButton = (props: QSRButtonProps) => {
    return <Button className={styles.button} {...props} />;
};

export default withTheme(QSRButton);
