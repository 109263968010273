import React, { forwardRef } from 'react';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { createComponents } from './createComponents';

const withTheme = <P extends object>(Component: React.ComponentType<P>) => {
    return forwardRef<unknown, P>((props, ref) => {
        const defaultTheme = useTheme();
        const theme = createTheme({
            ...defaultTheme,
            components: createComponents(),
        });

        return (
            <ThemeProvider theme={theme}>
                <Component ref={ref} {...props} />
            </ThemeProvider>
        );
    });
};

export default withTheme;
