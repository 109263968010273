export const routes = {
    HOME: '/',
    LOGIN: '/',
    ORDERS: '/orders',
    QSR_ORDERS: '/qsr-orders',
    PAYMENTS: '/payments',
    NOTIFICATION: '/notifications',
    REVIEWS: '/reviews',
    TABLE_VIEW: '/table-view',
    RESTAURANT_DETAILS: '/restaurant-details',
    MENUS: '/menus',
    QR_CODE: '/qr_code',
    SETTINGS: '/settings',
    FOODICS: '/pos/foodics',
    LS_K_SERIES: '/pos/ls-k-series',
    LS_O_SERIES: '/pos/ls-o-series',
    VPOS: '/pos/vpos',
    RESET_PASSWORD: '/reset-password',
    RESET_PASSWORD_VERIFY: '/reset-password/verify',
    DIGITAL_MENU: '/digital-menu',
    PRODUCTS: '/products',
    INVENTORY: '/digital-menu/inventory',
    TAX: '/tax-group',
    PRINTER: '/printer',
    PAYMENT_LINKS: '/payment-links',
    PAYMENT_LINK_DETAILS: '/payment-links/[id]',
    TAG: '/tag-group',
    REPORTS: '/reports',
    LINKIT: '/linkit',
    LOYALTY: '/loyalty',
    LOYALTY_MEMBER_DETAILS: '/loyalty/members/[id]',
};

export const queries = {
    PROFILE_SETTINGS: 'profile-settings',
    PASSWORD_AND_AUTHENTICATION: 'password-and-authentication',
    NOTIFICATION_SETTINGS: 'notification-settings',
    SCHEDULED_REPORTS: 'scheduled-reports',
};

export const apis = {
    TRANSACTION: '/transaction',
    DOWNLOAD_TRANSACTION_EXPORT: '/transaction/download/:restaurant_id',
    DOWNLOAD_ORDER_EXPORT: '/order/download/:restaurant_id',
    DOWNLOAD_TRANSACTION_BRAND_EXPORT: '/transaction/brand/download/:brand_id',
    DOWNLOAD_ORDER_BRAND_EXPORT: '/order/brand/download/:brand_id',
    ORDER_SEARCH_TRANSACTION: '/transaction/order-search',
    TRANSACTION_TABLE_SESSION_ID: '/transaction/:table-session-id',
    RESTAURANT: '/restaurant',
    DASHBOARD: '/dashboard',
    LOGIN: '/auth/login',
    ENABLE_MFA: '/auth/enable-mfa',
    VERIFY_TOKEN: '/auth/verify-token',
    ORDER_NOTIFICATION: '/order-notification',
    ORDER_NOTIFICATION_SEARCH: '/order-notification-search',
    UPLOAD: '/upload',
    RESET_PASSWORD: '/auth/reset-password',
    RESET_PASSWORD_VERIFY: '/auth/verify-password',
    RESTAURANT_POS_INTEGRATED: '/restaurant/:id/pos/:vendor',
    RESTAURANT_POS_UPDATE: '/restaurant/:id/pos/:vendor',
    REVENUE_WEEKLY_REPORT: '/vendor/dashboard/revenue/:name/weekly/:id',
    REVENUE_DAILY_REPORT: '/vendor/dashboard/revenue/:name/daily/:id',
    REVENUE_WoW_REPORT: '/vendor/dashboard/revenue-wow/:name/:id',
    REVENUE_ORDER_REPORT: '/vendor/dashboard/order/:name/weekly/:id',
    REVENUE_CHART_REPORT: '/vendor/dashboard/revenue-chart/:name/:period/:id',
    ORDER_REPORT: '/vendor/dashboard/order/dashboard/:id',
    BRAND_ORDER_REPORT: '/vendor/dashboard/order/brand/all/:id',
    QRS: 'vendor/restaurant/qr/:id',
    BEST_SELLING_ITEMS: '/vendor/best-selling-item/restaurant/:uniq?period=:period',
    USER_GET_CONFIG: '/vendor/user/config',
    USER_SET_CONFIG: '/vendor/user/config',
    VPOS_ORDER: '/vendor/restaurant/vpos/:id',
    DIGITAL_MENU_CREATE: 'digital-menu/create',
    DIGITAL_MENU_EDIT: 'digital-menu/edit',
    DIGITAL_MENU_DETAILS: 'digital-menu/details',
};

export const defaultPrefix = '/vendor';
