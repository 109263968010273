import * as React from 'react';

const Download: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 3" viewBox="0 0 24 24" {...props}>
        <path fill="none" d="M0 0h24v24H0z" />
        <path
            fill="none"
            stroke={props.color || 'rgba(108, 115, 127, 1)'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M12 3h4a5 5 0 0 1 5 5v6.929a5 5 0 0 1-1.464 3.535l-1.072 1.072A5 5 0 0 1 14.93 21H8a5 5 0 0 1-5-5v-4"
        />
        <path
            fill="none"
            stroke={props.color || 'rgba(108, 115, 127, 1)'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M21 15h-3a3 3 0 0 0-3 3v3M12 8h4M8 12h8M8 16h2M6 3v5M3.5 5.5 6 8M6 8l2.5-2.5"
        />
    </svg>
);

export default React.memo(Download);
