import * as React from 'react';

const SvgIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <g
            fill="none"
            stroke={props.color || 'rgba(108, 115, 127, 1)'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        >
            <path d="M18.364 5.636A9 9 0 1 1 5.636 18.364 9 9 0 0 1 18.364 5.636" />
            <path d="m15.15 11.898.875.937.875-.937" />
            <path d="M9.699 8.608A4.07 4.07 0 0 1 12 7.894a4.106 4.106 0 0 1 4.025 4.91M8.85 12.017l-.875-.936-.875.936" />
            <path d="M14.301 15.308a4.06 4.06 0 0 1-2.301.713 4.106 4.106 0 0 1-4.025-4.91" />
        </g>
        <path fill="none" d="M0 0h24v24H0Z" />
    </svg>
);

export default React.memo(SvgIcon);
