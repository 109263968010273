import * as React from 'react';

const Export: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <g
            fill="none"
            stroke={props.color || 'rgba(108, 115, 127, 1)'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        >
            <path d="M17 13H7a4 4 0 0 0-4 4v0a4 4 0 0 0 4 4h10a4 4 0 0 0 4-4v0a4 4 0 0 0-4-4M11 17h6" />
            <path d="M7.035 16.965a.05.05 0 0 1 0 .071.05.05 0 0 1-.071 0 .05.05 0 0 1 0-.071.05.05 0 0 1 .071 0M9 6l3-3 3 3M12 10V3" />
        </g>
        <path fill="none" d="M0 0h24v24H0z" />
    </svg>
);

export default React.memo(Export);
