import { Box } from '@mui/material';
import * as React from 'react';
import { SVGProps } from 'react';

export const DashboardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path
            fill="#616475"
            d="M5.1 20.775c-.516 0-.957-.184-1.324-.55a1.806 1.806 0 0 1-.55-1.325V5.1c0-.516.183-.957.55-1.324.367-.367.808-.551 1.324-.551h13.8c.516 0 .957.184 1.324.55.367.368.551.81.551 1.325v13.8c0 .516-.183.957-.55 1.324-.368.367-.81.551-1.325.551H5.1Zm4.963-1.875v-5.962H5.1V18.9h4.963Zm1.875 0H18.9v-5.962h-6.962V18.9ZM5.1 11.063h13.8V5.1H5.1v5.963Z"
        />
    </svg>
);

export const OrdersIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path
            fill="#616475"
            d="M22 6v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 20H4c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 18V6c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 4h16c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412ZM4 8h16V6H4v2Zm0 4v6h16v-6H4Z"
        />
    </svg>
);

export const PaymentsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path
            fill="#616475"
            d="M3 22V2l1.5 1.5L6 2l1.5 1.5L9 2l1.5 1.5L12 2l1.5 1.5L15 2l1.5 1.5L18 2l1.5 1.5L21 2v20l-1.5-1.5L18 22l-1.5-1.5L15 22l-1.5-1.5L12 22l-1.5-1.5L9 22l-1.5-1.5L6 22l-1.5-1.5L3 22Zm3-5h12v-2H6v2Zm0-4h12v-2H6v2Zm0-4h12V7H6v2ZM5 19.1h14V4.9H5v14.2Z"
        />
    </svg>
);

export const TableViewIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path
            fill="#616475"
            d="m6 20 1.5-3.75c.15-.383.392-.688.725-.912A1.965 1.965 0 0 1 9.35 15H11v-4.025c-2.55-.083-4.688-.458-6.412-1.125C2.862 9.183 2 8.4 2 7.5c0-.967.975-1.792 2.925-2.475C6.875 4.342 9.233 4 12 4c2.783 0 5.146.342 7.087 1.025C21.03 5.708 22 6.533 22 7.5c0 .9-.863 1.683-2.587 2.35-1.726.667-3.863 1.042-6.413 1.125V15h1.65c.4 0 .77.113 1.112.338.342.225.588.529.738.912L18 20h-2l-1.2-3H9.2L8 20H6Zm6-11a23.57 23.57 0 0 0 4.575-.425c1.433-.283 2.483-.642 3.15-1.075-.667-.433-1.717-.792-3.15-1.075A23.57 23.57 0 0 0 12 6a23.57 23.57 0 0 0-4.575.425c-1.433.283-2.483.642-3.15 1.075.667.433 1.717.792 3.15 1.075A23.57 23.57 0 0 0 12 9Z"
        />
    </svg>
);

export const QsrOrdersIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path
            fill="#616475"
            d="M5 22c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 20V8c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 5 6h2c0-1.383.487-2.563 1.463-3.538C9.438 1.487 10.617 1 12 1s2.563.488 3.537 1.462C16.512 3.438 17 4.617 17 6h2c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 22H5Zm0-2h14V8H5v12Zm7-6c1.383 0 2.563-.488 3.537-1.463C16.512 11.563 17 10.383 17 9h-2c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0 1 12 12a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 9 9H7c0 1.383.487 2.563 1.463 3.537C9.438 13.512 10.617 14 12 14ZM9 6h6c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 12 3c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 9 6Z"
        />
    </svg>
);

export const PaymentLinksIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path
            fill="#616475"
            d="M11 19h2v-1h1c.283 0 .52-.096.713-.288A.968.968 0 0 0 15 17v-3a.968.968 0 0 0-.287-.713A.968.968 0 0 0 14 13h-3v-1h4v-2h-2V9h-2v1h-1a.967.967 0 0 0-.713.287A.968.968 0 0 0 9 11v3c0 .283.096.52.287.713.192.191.43.287.713.287h3v1H9v2h2v1Zm-5 3c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 4 20V4c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 6 2h8l6 6v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 18 22H6Zm7-14V4H6v16h12V8h-5Z"
        />
    </svg>
);

export const RestaurantIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path
            fill="#616475"
            d="M20.825 11.075v7.85c0 .516-.184.957-.55 1.324-.368.367-.81.551-1.325.551H5.075c-.516 0-.957-.184-1.324-.55a1.806 1.806 0 0 1-.551-1.325v-7.85c-.408-.317-.71-.756-.906-1.319-.196-.562-.194-1.164.006-1.806l1.025-3.325c.132-.419.366-.765.703-1.04.337-.273.728-.41 1.172-.41h13.626c.441 0 .828.131 1.162.394.333.262.572.614.718 1.054l1.019 3.327c.2.642.202 1.238.006 1.788s-.498.995-.906 1.337Zm-6.65-1c.467 0 .815-.154 1.044-.462.229-.309.319-.655.269-1.038L14.95 5.05h-2v3.675c0 .364.12.68.357.948.239.268.528.402.868.402Zm-4.426 0c.391 0 .71-.134.956-.402.247-.269.37-.584.37-.948V5.05h-2l-.537 3.525c-.059.392.03.74.268 1.044.238.304.552.456.943.456Zm-4.374 0c.317 0 .588-.11.813-.332A1.44 1.44 0 0 0 6.6 8.9l.563-3.85H5.138L4.125 8.375c-.117.392-.06.773.169 1.144.229.37.59.556 1.081.556Zm13.275 0c.483 0 .846-.181 1.088-.544.241-.362.295-.748.162-1.156L18.863 5.05h-2l.56 3.85c.052.333.19.613.415.838.224.225.495.337.812.337Zm-13.575 8.85H18.95v-7.013a.6.6 0 0 1-.175.038h-.125a2.85 2.85 0 0 1-1.17-.225c-.337-.15-.665-.392-.985-.725-.288.3-.622.533-1.002.7-.38.167-.783.25-1.212.25-.446 0-.858-.083-1.237-.25a3.32 3.32 0 0 1-1.019-.7c-.283.3-.613.533-.988.7-.375.167-.77.25-1.188.25-.466 0-.895-.08-1.286-.237A2.928 2.928 0 0 1 7.525 11c-.367.367-.715.617-1.044.75-.329.133-.698.2-1.106.2H5.23a.343.343 0 0 1-.155-.037v7.012Z"
        />
    </svg>
);
export const MenusIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path
            fill="#616475"
            d="m4.487 20.8-1.325-1.325L13.262 9.4c-.3-.7-.343-1.477-.13-2.331.212-.854.672-1.627 1.38-2.32.842-.84 1.794-1.343 2.857-1.505 1.062-.163 1.927.09 2.593.756.667.667.92 1.531.757 2.594-.163 1.062-.665 2.014-1.507 2.856-.691.708-1.464 1.169-2.318 1.381-.855.213-1.632.169-2.332-.131L13.287 12l7.475 7.475-1.3 1.325-7.5-7.45-7.475 7.45Zm2.9-8.425L4.462 9.45c-.875-.875-1.314-1.917-1.318-3.125-.005-1.208.427-2.25 1.293-3.125l6.05 6.1-3.1 3.075Z"
        />
    </svg>
);

export const QrCodeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path
            fill="#616475"
            d="M3 11V3h8v8H3Zm2-2h4V5H5v4ZM3 21v-8h8v8H3Zm2-2h4v-4H5v4Zm8-8V3h8v8h-8Zm2-2h4V5h-4v4Zm4 12v-2h2v2h-2Zm-6-6v-2h2v2h-2Zm2 2v-2h2v2h-2Zm-2 2v-2h2v2h-2Zm2 2v-2h2v2h-2Zm2-2v-2h2v2h-2Zm0-4v-2h2v2h-2Zm2 2v-2h2v2h-2Z"
        />
    </svg>
);

export const ProfileIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path
            fill="#616475"
            d="M5.987 17.088a10.788 10.788 0 0 1 2.807-1.5A9.47 9.47 0 0 1 12 15.05a9.47 9.47 0 0 1 3.206.538c1.004.358 1.94.858 2.806 1.5A7.653 7.653 0 0 0 19.4 14.74c.317-.856.475-1.77.475-2.742 0-2.19-.765-4.05-2.294-5.58C16.052 4.89 14.191 4.126 12 4.126c-2.192 0-4.052.765-5.581 2.294-1.53 1.529-2.294 3.389-2.294 5.58 0 .971.158 1.885.475 2.742.317.856.78 1.638 1.388 2.347Zm6.016-4.138c-.969 0-1.786-.332-2.453-.997-.667-.665-1-1.482-1-2.45 0-.969.332-1.786.997-2.453.665-.667 1.482-1 2.45-1 .969 0 1.786.333 2.453.997.667.665 1 1.482 1 2.45 0 .969-.332 1.787-.997 2.453-.665.667-1.482 1-2.45 1ZM12 21.75a9.475 9.475 0 0 1-3.802-.769 9.842 9.842 0 0 1-3.095-2.088 9.894 9.894 0 0 1-2.084-3.095A9.462 9.462 0 0 1 2.25 12c0-1.348.256-2.616.77-3.802a9.843 9.843 0 0 1 2.087-3.094 9.895 9.895 0 0 1 3.095-2.085A9.462 9.462 0 0 1 12 2.25c1.348 0 2.616.256 3.802.77a9.844 9.844 0 0 1 3.095 2.087 9.897 9.897 0 0 1 2.084 3.095A9.464 9.464 0 0 1 21.75 12a9.476 9.476 0 0 1-.77 3.802 9.842 9.842 0 0 1-2.087 3.095 9.894 9.894 0 0 1-3.095 2.084 9.462 9.462 0 0 1-3.798.77Zm0-1.875c.867 0 1.681-.125 2.444-.375a7.932 7.932 0 0 0 2.156-1.1 8.28 8.28 0 0 0-2.156-1.094 7.673 7.673 0 0 0-2.443-.38c-.865 0-1.682.124-2.45.374A7.719 7.719 0 0 0 7.4 18.4c.675.484 1.394.85 2.156 1.1.763.25 1.577.375 2.444.375Zm0-8.8c.442 0 .815-.152 1.119-.456.304-.304.456-.677.456-1.119 0-.442-.152-.814-.456-1.119A1.521 1.521 0 0 0 12 7.925c-.442 0-.815.152-1.119.456a1.521 1.521 0 0 0-.456 1.12c0 .44.152.814.456 1.118.304.304.677.456 1.119.456Z"
        />
    </svg>
);

export const AuthIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path
            fill="#616475"
            d="M6.1 21.75c-.516 0-.957-.183-1.324-.55a1.805 1.805 0 0 1-.55-1.325v-9.85c0-.516.183-.957.55-1.324.367-.367.808-.55 1.324-.55h1.075V6.204c0-1.345.469-2.486 1.406-3.424.938-.937 2.077-1.406 3.42-1.406 1.34 0 2.48.469 3.418 1.406.937.938 1.406 2.08 1.406 3.424V8.15H17.9c.516 0 .957.184 1.324.55.367.368.551.81.551 1.325v9.85c0 .516-.183.957-.55 1.324-.368.367-.81.551-1.325.551H6.1Zm0-1.875h11.8v-9.85H6.1v9.85Zm5.9-3c.53 0 .983-.188 1.36-.565.377-.377.565-.83.565-1.36 0-.53-.188-.982-.565-1.36a1.853 1.853 0 0 0-1.36-.565c-.53 0-.982.189-1.36.566-.376.377-.565.83-.565 1.36 0 .528.189.982.566 1.359.377.377.83.565 1.36.565ZM9.05 8.15h5.9V6.196c0-.814-.286-1.508-.86-2.083a2.837 2.837 0 0 0-2.087-.863 2.85 2.85 0 0 0-2.09.86A2.841 2.841 0 0 0 9.05 6.2v1.95Z"
        />
    </svg>
);

export const NotificationIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path
            fill="#616475"
            d="M2.388 10.125c0-1.6.356-3.07 1.068-4.412a9.782 9.782 0 0 1 2.87-3.338l1.1 1.513A7.818 7.818 0 0 0 5.12 6.57c-.572 1.08-.857 2.265-.857 3.554H2.388Zm17.35 0c0-1.29-.286-2.474-.857-3.554a7.82 7.82 0 0 0-2.306-2.683l1.1-1.513a9.783 9.783 0 0 1 2.87 3.338c.712 1.341 1.068 2.812 1.068 4.412h-1.875ZM4.163 18.813v-1.875h1.875v-6.775c0-1.384.418-2.613 1.256-3.688A5.86 5.86 0 0 1 10.55 4.35V3.7c0-.4.14-.739.422-1.018a1.4 1.4 0 0 1 1.025-.42c.402 0 .745.14 1.028.42.284.28.425.619.425 1.018v.65a5.86 5.86 0 0 1 3.256 2.125c.838 1.075 1.257 2.304 1.257 3.688v6.775h1.875v1.875H4.163ZM12 21.7a1.88 1.88 0 0 1-1.381-.569 1.88 1.88 0 0 1-.569-1.38h3.9a1.88 1.88 0 0 1-.569 1.38c-.379.38-.84.57-1.38.57Zm-4.087-4.762h8.175v-6.775c0-1.124-.4-2.087-1.201-2.887-.801-.8-1.764-1.2-2.888-1.2-1.124 0-2.086.4-2.886 1.2-.8.8-1.2 1.763-1.2 2.887v6.775Z"
        />
    </svg>
);

export const ScheduledReportsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <path
            fill="#616475"
            d="m19.6 20.175.7-.7-1.875-1.875v-2.8h-1V18l2.175 2.175Zm-14.5.575c-.516 0-.957-.183-1.324-.55a1.806 1.806 0 0 1-.55-1.325V5.1c0-.516.183-.957.55-1.324.367-.367.808-.55 1.324-.55h13.8c.516 0 .957.183 1.324.55.367.367.551.808.551 1.324v6.663a7.746 7.746 0 0 0-.917-.361 6.198 6.198 0 0 0-.958-.227V5.1H5.1v13.775h6.198c.052.342.13.67.236.982.106.314.232.611.379.893H5.1Zm0-2.95v1.075V5.1v6.075-.075 6.7Zm2.025-.95h4.163c.05-.325.123-.644.218-.956.096-.313.21-.619.344-.919H7.125v1.875Zm0-3.925h6.213a7.158 7.158 0 0 1 1.618-1.112 6.779 6.779 0 0 1 1.92-.625v-.138h-9.75v1.875Zm0-3.925h9.75V7.125h-9.75V9Zm10.802 13.625c-1.335 0-2.473-.47-3.414-1.41-.942-.941-1.413-2.079-1.413-3.413 0-1.335.47-2.473 1.41-3.414.941-.942 2.079-1.413 3.413-1.413 1.335 0 2.473.47 3.415 1.41.941.941 1.412 2.079 1.412 3.413 0 1.335-.47 2.473-1.41 3.415-.941.941-2.079 1.412-3.413 1.412Z"
        />
    </svg>
);

export const RightChevron = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
        <path
            fill="#616475"
            d="M6 11.526a.664.664 0 0 1 0-.94L8.587 8 6 5.413a.664.664 0 1 1 .94-.94L10 7.533c.26.26.26.68 0 .94l-3.06 3.06a.67.67 0 0 1-.94-.007Z"
        />
    </svg>
);

export const LeftChevron = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
        <path
            fill="#616475"
            d="M10 4.474c.26.26.26.68 0 .94L7.413 8 10 10.587a.664.664 0 1 1-.94.94L6 8.467a.664.664 0 0 1 0-.94l3.06-3.06a.67.67 0 0 1 .94.007Z"
        />
    </svg>
);

export const WhatsappIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <path
            fill="#fff"
            fillRule="evenodd"
            stroke="null"
            d="M20.463 3.46A11.625 11.625 0 0 0 12.177.025C5.722.025.468 5.279.468 11.738a11.712 11.712 0 0 0 1.569 5.855L.37 23.664l6.21-1.63a11.706 11.706 0 0 0 5.595 1.428 11.716 11.716 0 0 0 8.288-20V3.46zM12.18 21.49a9.72 9.72 0 0 1-4.959-1.363l-.356-.213-3.686.966.98-3.593-.23-.368A9.74 9.74 0 0 1 19.07 4.854a9.745 9.745 0 0 1-6.89 16.635zm5.342-7.299c-.295-.148-1.732-.853-1.999-.951s-.463-.148-.662.148-.756.951-.927 1.148-.342.223-.632.075-1.238-.457-2.352-1.455a8.929 8.929 0 0 1-1.637-2.027c-.172-.294-.019-.452.13-.595s.294-.34.438-.512a1.926 1.926 0 0 0 .295-.49.54.54 0 0 0-.024-.513c-.08-.147-.667-1.588-.903-2.175s-.479-.49-.66-.504-.365-.008-.558-.008a1.078 1.078 0 0 0-.784.365C6.984 6.988 6.227 7.7 6.227 9.14s1.047 2.83 1.194 3.028 2.065 3.153 5.005 4.42c.698.3 1.243.48 1.666.62a4.036 4.036 0 0 0 1.843.115c.562-.083 1.73-.707 1.977-1.39s.245-1.274.171-1.39-.263-.203-.558-.353l-.002.001z"
            className="cls-1"
        />
    </svg>
);

export const StarIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 15" // Adjust these values based on the original size of your icon
        width={props.width || 16}
        height={props.height || 15}
        fill="none"
        style={{ marginRight: '5px' }}
        {...props}
    >
        <path
            d="M4.57367 15.011C4.36444 15.1202 4.12879 15.1686 3.89346 15.1507C3.65812 15.1329 3.43249 15.0494 3.24216 14.9098C3.05183 14.7703 2.90441 14.5802 2.81661 14.3611C2.72882 14.142 2.70415 13.9027 2.74542 13.6703L3.42669 9.7654L0.558421 7.01671C0.386606 6.85286 0.264446 6.6439 0.205949 6.4138C0.147451 6.1837 0.154985 5.94178 0.227685 5.71576C0.300386 5.48975 0.43531 5.2888 0.616987 5.13596C0.798664 4.98311 1.01974 4.88456 1.25486 4.85161L5.23726 4.28233L7.03519 0.695731C7.1397 0.484861 7.30104 0.307379 7.50101 0.183298C7.70099 0.0592157 7.93166 -0.00653172 8.16701 -0.00653172C8.40235 -0.00653172 8.63302 0.0592157 8.833 0.183298C9.03297 0.307379 9.19431 0.484861 9.29882 0.695731L11.0968 4.28233L15.0792 4.85161C15.3143 4.88456 15.5353 4.98311 15.717 5.13596C15.8987 5.2888 16.0336 5.48975 16.1063 5.71576C16.179 5.94178 16.1866 6.1837 16.1281 6.4138C16.0696 6.6439 15.9474 6.85286 15.7756 7.01671L12.9073 9.7654L13.5886 13.6712C13.6299 13.9036 13.6052 14.1428 13.5174 14.3619C13.4296 14.581 13.2822 14.7711 13.0918 14.9107C12.9015 15.0502 12.6759 15.1337 12.4406 15.1516C12.2052 15.1695 11.9696 15.121 11.7603 15.0118L8.16701 13.1549L4.57367 15.011Z"
            fill={props.fill || '#D2D2D2'}
        />
    </svg>
);

export const LoyaltyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.459 16.133L11.526 15.572C11.823 15.416 12.178 15.416 12.475 15.572L13.542 16.133C13.886 16.314 14.302 16.284 14.616 16.055L15.014 15.766C15.328 15.538 15.485 15.151 15.42 14.769L15.216 13.581C15.159 13.25 15.269 12.913 15.509 12.678L16.372 11.837C16.65 11.566 16.75 11.161 16.63 10.792L16.478 10.324C16.358 9.955 16.039 9.686 15.655 9.63L14.462 9.457C14.13 9.409 13.843 9.2 13.694 8.899L13.16 7.818C12.989 7.47 12.634 7.25 12.246 7.25H11.754C11.366 7.25 11.011 7.47 10.84 7.818L10.307 8.899C10.158 9.2 9.87102 9.409 9.53902 9.457L8.34602 9.63C7.96202 9.686 7.64302 9.955 7.52302 10.324L7.37102 10.792C7.25102 11.161 7.35102 11.566 7.62902 11.837L8.49202 12.678C8.73202 12.912 8.84202 13.25 8.78502 13.581L8.58102 14.769C8.51502 15.152 8.67302 15.538 8.98702 15.766L9.38502 16.055C9.69902 16.283 10.115 16.313 10.459 16.133V16.133Z"
            stroke="#616475"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.63601 5.63601C9.15101 2.12101 14.849 2.12101 18.364 5.63601C21.879 9.15101 21.879 14.849 18.364 18.364C14.849 21.879 9.15101 21.879 5.63601 18.364C2.12101 14.849 2.12101 9.15101 5.63601 5.63601"
            stroke="#616475"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const PhoneIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M16 21H8C6.895 21 6 20.105 6 19V5C6 3.895 6.895 3 8 3H16C17.105 3 18 3.895 18 5V19C18 20.105 17.105 21 16 21Z"
            stroke="#303030"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle cx="12" cy="17.25" r="0.75" fill="#303030" />
        <path d="M10.75 6H13.25" stroke="#303030" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const LaptopMoneyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.5028 17.0021H15.0363C14.7019 17.002 14.3896 17.1691 14.204 17.4473L13.7978 18.0565C13.6122 18.3347 13.2999 18.5017 12.9655 18.5017H9.03382C8.6994 18.5017 8.38707 18.3347 8.20147 18.0565L7.7953 17.4473C7.60929 17.169 7.29665 17.002 6.96195 17.0021H3.49651C2.94408 17.0023 2.49629 17.4501 2.49609 18.0025V19.0029C2.49609 19.5334 2.70655 20.0423 3.08157 20.4175C3.45659 20.7927 3.96532 21.0036 4.49583 21.0037H17.5023C18.0329 21.0039 18.5417 20.7933 18.9169 20.4183C19.2922 20.0432 19.5032 19.5345 19.5032 19.004V18.0025C19.503 17.4501 19.0552 17.0023 18.5028 17.0021V17.0021Z"
            stroke="#323232"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.5029 17.0021V14.0008"
            stroke="#323232"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2917 10.9996H19.9623C20.4045 10.9995 20.8285 10.8237 21.1411 10.511C21.4537 10.1982 21.6292 9.77406 21.629 9.33189V4.66394C21.629 4.22161 21.4535 3.79735 21.1407 3.48457C20.8279 3.1718 20.4037 2.99613 19.9613 2.99625H11.2907C10.8485 2.99634 10.4245 3.1721 10.1119 3.48487C9.79936 3.79763 9.62385 4.22176 9.62402 4.66394V9.33289C9.62402 9.77507 9.79988 10.1991 10.1126 10.5117C10.4254 10.8242 10.8495 10.9998 11.2917 10.9996V10.9996Z"
            stroke="#323232"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle
            cx="15.6266"
            cy="6.99797"
            r="1.50063"
            stroke="#323232"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.49658 17.0021V6.99792C3.49643 6.46741 3.70704 5.95857 4.08206 5.58334C4.45708 5.20811 4.96581 4.99723 5.49632 4.99709H6.99804"
            stroke="#323232"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const QlubIcon = (props: SVGProps<SVGSVGElement>) => (
    <Box
        sx={{
            display: 'flex',
            width: '78px',
            height: '33px',
            padding: '6.226px 7.922px 6.226px 7.313px',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="21" viewBox="0 0 64 21" fill="none" {...props}>
            <g clipPath="url(#clip0_5653_821)">
                <path
                    d="M54.8485 6.65216C55.2975 6.65216 55.6614 6.30163 55.6614 5.86923C55.6614 5.43683 55.2975 5.0863 54.8485 5.0863C54.3996 5.0863 54.0356 5.43683 54.0356 5.86923C54.0356 6.30163 54.3996 6.65216 54.8485 6.65216Z"
                    fill="#7D00D4"
                />
                <path
                    d="M52.657 8.80478C53.1059 8.80478 53.4699 8.45425 53.4699 8.02185C53.4699 7.58945 53.1059 7.23892 52.657 7.23892C52.2081 7.23892 51.8441 7.58945 51.8441 8.02185C51.8441 8.45425 52.2081 8.80478 52.657 8.80478Z"
                    fill="#7D00D4"
                />
                <path
                    d="M52.657 6.65216C53.1059 6.65216 53.4699 6.30163 53.4699 5.86923C53.4699 5.43683 53.1059 5.0863 52.657 5.0863C52.2081 5.0863 51.8441 5.43683 51.8441 5.86923C51.8441 6.30163 52.2081 6.65216 52.657 6.65216Z"
                    fill="#7D00D4"
                />
                <path
                    d="M60.0249 6.67921C60.4739 6.67921 60.8378 6.32868 60.8378 5.89628C60.8378 5.46389 60.4739 5.11336 60.0249 5.11336C59.576 5.11336 59.2121 5.46389 59.2121 5.89628C59.2121 6.32868 59.576 6.67921 60.0249 6.67921Z"
                    fill="#7D00D4"
                />
                <path
                    d="M62.2164 8.83184C62.6654 8.83184 63.0293 8.48132 63.0293 8.04892C63.0293 7.61652 62.6654 7.26599 62.2164 7.26599C61.7675 7.26599 61.4036 7.61652 61.4036 8.04892C61.4036 8.48132 61.7675 8.83184 62.2164 8.83184Z"
                    fill="#7D00D4"
                />
                <path
                    d="M62.2164 6.67921C62.6654 6.67921 63.0293 6.32868 63.0293 5.89628C63.0293 5.46389 62.6654 5.11336 62.2164 5.11336C61.7675 5.11336 61.4036 5.46389 61.4036 5.89628C61.4036 6.32868 61.7675 6.67921 62.2164 6.67921Z"
                    fill="#7D00D4"
                />
                <path
                    d="M54.8973 16.5968C55.3462 16.5968 55.7102 16.2463 55.7102 15.8139C55.7102 15.3815 55.3462 15.031 54.8973 15.031C54.4483 15.031 54.0844 15.3815 54.0844 15.8139C54.0844 16.2463 54.4483 16.5968 54.8973 16.5968Z"
                    fill="#7D00D4"
                />
                <path
                    d="M52.7058 14.4442C53.1547 14.4442 53.5186 14.0937 53.5186 13.6613C53.5186 13.2289 53.1547 12.8784 52.7058 12.8784C52.2568 12.8784 51.8929 13.2289 51.8929 13.6613C51.8929 14.0937 52.2568 14.4442 52.7058 14.4442Z"
                    fill="#7D00D4"
                />
                <path
                    d="M52.7058 16.5968C53.1547 16.5968 53.5186 16.2463 53.5186 15.8139C53.5186 15.3815 53.1547 15.031 52.7058 15.031C52.2568 15.031 51.8929 15.3815 51.8929 15.8139C51.8929 16.2463 52.2568 16.5968 52.7058 16.5968Z"
                    fill="#7D00D4"
                />
                <path
                    d="M60.0737 16.5698C60.5227 16.5698 60.8866 16.2193 60.8866 15.7869C60.8866 15.3545 60.5227 15.0039 60.0737 15.0039C59.6248 15.0039 59.2608 15.3545 59.2608 15.7869C59.2608 16.2193 59.6248 16.5698 60.0737 16.5698Z"
                    fill="#7D00D4"
                />
                <path
                    d="M62.2652 14.4172C62.7142 14.4172 63.0781 14.0666 63.0781 13.6342C63.0781 13.2018 62.7142 12.8513 62.2652 12.8513C61.8163 12.8513 61.4524 13.2018 61.4524 13.6342C61.4524 14.0666 61.8163 14.4172 62.2652 14.4172Z"
                    fill="#7D00D4"
                />
                <path
                    d="M62.2652 16.5698C62.7142 16.5698 63.0781 16.2193 63.0781 15.7869C63.0781 15.3545 62.7142 15.0039 62.2652 15.0039C61.8163 15.0039 61.4524 15.3545 61.4524 15.7869C61.4524 16.2193 61.8163 16.5698 62.2652 16.5698Z"
                    fill="#7D00D4"
                />
                <path
                    d="M12.4504 5.17931V20.7736H8.47868V15.1578C8.1519 15.6533 7.69344 16.0473 7.10654 16.3415C6.51802 16.6357 5.87584 16.7829 5.17677 16.7829C4.22407 16.7829 3.3803 16.5427 2.64384 16.0642C1.90737 15.5856 1.3351 14.9008 0.925411 14.0096C0.517345 13.1202 0.3125 12.087 0.3125 10.9101C0.3125 9.73483 0.520597 8.6898 0.936791 7.77666C1.35298 6.86353 1.93013 6.1567 2.6666 5.65279C3.40307 5.15056 4.24033 4.89861 5.17677 4.89861C5.90511 4.89861 6.57167 5.05418 7.1732 5.36194C7.77635 5.67139 8.23319 6.09751 8.54534 6.63863V5.17593H12.4504V5.17931ZM7.9877 12.9646C8.36 12.4776 8.54534 11.7775 8.54534 10.8644C8.54534 9.93605 8.35837 9.22415 7.9877 8.72869C7.6154 8.23323 7.08053 7.98635 6.38145 7.98635C5.71164 7.98635 5.18815 8.24168 4.80772 8.75236C4.42729 9.26304 4.2387 9.98171 4.2387 10.9101C4.2387 11.808 4.42404 12.4962 4.79634 12.9748C5.16864 13.455 5.69538 13.6934 6.37983 13.6934C7.08053 13.6951 7.6154 13.4516 7.9877 12.9646Z"
                    fill="#7D00D4"
                />
                <path
                    d="M33.5788 5.17931V16.5968H29.7192V15.0648C29.3762 15.6211 28.9421 16.0473 28.4137 16.3415C27.8854 16.6357 27.2789 16.7829 26.5945 16.7829C25.1817 16.7829 24.1331 16.377 23.4487 15.5636C22.7642 14.752 22.4228 13.5176 22.4228 11.8621V5.17931H26.3945V11.9551C26.3945 13.0694 26.8546 13.6258 27.7781 13.6258C28.3129 13.6258 28.7519 13.4246 29.0949 13.0221C29.4363 12.6196 29.6087 12.0937 29.6087 11.4444V5.17931H33.5788Z"
                    fill="#7D00D4"
                />
                <path
                    d="M45.6923 5.62066C46.4206 6.1009 46.9896 6.78575 47.3993 7.6752C47.8074 8.56466 48.0122 9.59786 48.0122 10.7731C48.0122 11.9483 47.8074 12.9934 47.3993 13.9065C46.9896 14.8196 46.4174 15.5265 45.6809 16.0304C44.9444 16.5326 44.1072 16.7845 43.1707 16.7845C42.4424 16.7845 41.7758 16.629 41.1743 16.3212C40.5711 16.0118 40.1143 15.5873 39.8021 15.0445V16.5985H35.8971V0.236557H39.8688V6.52533C40.1956 6.02987 40.6524 5.63588 41.2409 5.34164C41.8278 5.04741 42.4716 4.9003 43.1707 4.9003C44.1234 4.9003 44.9639 5.14042 45.6923 5.62066ZM43.5284 12.9409C43.9007 12.4387 44.086 11.715 44.086 10.7714C44.086 9.87518 43.9039 9.18526 43.5398 8.70671C43.1756 8.22816 42.6505 7.98804 41.9677 7.98804C41.2686 7.98804 40.7321 8.23154 40.3614 8.71854C39.9891 9.20555 39.8038 9.90561 39.8038 10.8187C39.8038 11.7471 39.9891 12.459 40.3614 12.9545C40.7337 13.4499 41.2621 13.6968 41.9449 13.6968C42.6293 13.6951 43.1577 13.4448 43.5284 12.9409Z"
                    fill="#7D00D4"
                />
                <path
                    d="M14.3314 0.22641H18.547V12.0887C18.547 12.0887 18.5242 13.1354 19.5306 13.1608C20.5369 13.1861 20.9596 13.1608 20.9596 13.1608V16.4311C20.2817 16.5461 19.5859 16.5968 18.8998 16.5968C16.8611 16.5968 14.5119 15.5738 14.333 13.2098C14.3314 11.1383 14.3314 0.22641 14.3314 0.22641Z"
                    fill="#7D00D4"
                />
            </g>
            <defs>
                <clipPath id="clip0_5653_821">
                    <rect width="62.7656" height="20.5472" fill="white" transform="translate(0.3125 0.22641)" />
                </clipPath>
            </defs>
        </svg>
    </Box>
);
