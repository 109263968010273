import { createContext, useState, useContext, useMemo } from 'react';

interface NavbarContextType {
    isSettingsOpen: boolean;
    setIsSettingsOpen: (value: boolean) => void;
    refresh: boolean;
    toggleRefresh: (value: boolean) => void;
    openProductModal: boolean;
    setOpenProductModal: (value: boolean) => void;
}

const NavbarContext = createContext<NavbarContextType | undefined>(undefined);

interface Props {
    children: React.ReactNode;
}

export function NavbarContextProvider({ children }: Props) {
    const [openProductModal, setOpenProductModal] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
    const [refresh, toggleRefresh] = useState<boolean>(false);

    const value = useMemo(
        () => ({
            isSettingsOpen,
            setIsSettingsOpen,
            refresh,
            toggleRefresh,
            openProductModal,
            setOpenProductModal,
        }),
        [isSettingsOpen, refresh, openProductModal],
    );

    return <NavbarContext.Provider value={value}>{children}</NavbarContext.Provider>;
}

export const useNavbarContext = () => {
    const context = useContext(NavbarContext);
    if (!context) {
        throw new Error('useNavbarContext must be used within a NavbarContextProvider');
    }
    return context;
};
